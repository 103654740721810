'use client';

import { Button } from '@/components/ui/button';
import { useInfoTranslation } from '@/hooks/i18n';
import { ArrowLeft } from 'lucide-react';
import Image from 'next/image';

export default function RootPage() {
  const infoTrans = useInfoTranslation();
  return (
    <main className='min-h-screen'>
      <div className='flex h-dvh w-full flex-col items-center justify-center'>
        <Image alt='logo' src='/logo/logo-full.svg' width={400} height={200} />
        <h1 className='text-center font-serif text-4xl'>
          {infoTrans('InDevelopment')}
        </h1>
        <Button
          className='mt-4'
          onClick={() => {
            window.location.href = 'https://konglass.com';
          }}
        >
          <ArrowLeft className='mr-2' />
          {infoTrans('GoToV1')}
        </Button>
      </div>
    </main>
  );
}
